<template>
  <div id="send-members-email">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>
      <v-card-text>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="submitForm"
        >
          <v-container>
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12" align="center">
                <h1 class="text-primary">Email Credits : {{ EmailCredits }}</h1>
              </v-col>
              <v-col cols="12" md="12">
                <p>
                  <span class="text-danger">*</span> indicates required field
                </p>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <label>
                  <h6><span class="text-danger">*</span> Year</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  v-model="JciYearCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <label>
                  <h6><span class="text-danger">*</span> Organization Type</h6>
                </label>
                <v-select
                  :reduce="(option) => option.value"
                  :items="OrganizationTypeOptions1"
                  :rules="OrganizationTypeRules1"
                  v-model="OrganizationType1"
                  dense
                  clearable
                  required
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <label>
                  <h6><span class="text-danger">*</span> Member Type</h6>
                </label>
                <v-select
                  :reduce="(option) => option.value"
                  v-model="MemberType"
                  :items="MemberTypeOptions"
                  :rules="MemberTypeRules"
                  :loading="MemberTypeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3" v-if="MemberType == 1">
                <label>
                  <h6><span class="text-danger">*</span> Zone</h6>
                </label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3" v-if="MemberType == 1">
                <label>
                  <h6>LO</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  :rules="LomCodeRules"
                  v-model="LomCode"
                  dense
                  clearable
                  outlined
                  multiple
                  chips
                  deletable-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3" v-if="MemberType == 1">
                <label>
                  <h6>Half</h6>
                </label>
                <v-select
                  :reduce="(option) => option.value"
                  :items="YearHalfOptions"
                  :rules="YearHalfRules"
                  v-model="YearHalf"
                  dense
                  clearable
                  required
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3" v-if="MemberType == 2">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Governing Board Type
                  </h6>
                </label>
                <v-select
                  @change="getDesignationCodeOptions"
                  :reduce="(option) => option.value"
                  :items="OrganizationTypeOptions"
                  :rules="OrganizationTypeRules"
                  v-model="OrganizationType"
                  dense
                  clearable
                  required
                  outlined
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                lg="3"
                v-if="
                  (MemberType == 2 && OrganizationType == 2) ||
                  OrganizationType == 3
                "
              >
                <label>
                  <h6>Area</h6>
                </label>
                <v-autocomplete
                  @change="getDesignationZoneCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="DesignationAreaCodeOptionsLoading"
                  :items="DesignationAreaCodeOptions"
                  :rules="DesignationAreaCodeRules"
                  v-model="DesignationAreaCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                lg="3"
                v-if="
                  (MemberType == 2 && OrganizationType == 2) ||
                  OrganizationType == 3
                "
              >
                <label>
                  <h6>Zone</h6>
                </label>
                <v-autocomplete
                  @change="getDesignationLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="DesignationZoneCodeOptionsLoading"
                  :items="DesignationZoneCodeOptions"
                  :rules="DesignationZoneCodeRules"
                  v-model="DesignationZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                lg="3"
                v-if="MemberType == 2 && OrganizationType == 3"
              >
                <label>
                  <h6>LOM</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="DesignationLomCodeOptionsLoading"
                  :items="DesignationLomCodeOptions"
                  :rules="DesignationLomCodeRules"
                  v-model="DesignationLomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3" v-if="MemberType == 2">
                <label>
                  <h6>Designation</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="DesignationCodeOptionsLoading"
                  :items="DesignationCodeOptions"
                  :rules="DesignationCodeRules"
                  v-model="DesignationCode"
                  dense
                  clearable
                  outlined
                  multiple
                  chips
                  deletable-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h4 class="text-primary">
                    <span class="text-danger">*</span> Reply To Email ID
                  </h4>
                </label>
                <v-text-field
                  v-model="ReplyToEmailId"
                  :rules="ReplyToEmailIdRules"
                  placeholder="Reply to email id"
                  hint="Enter the Email"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <label>
                  <h4 class="text-primary">
                    <span class="text-danger">*</span> Subject
                  </h4>
                </label>
                <v-text-field
                  v-model="Subject"
                  :rules="SubjectRules"
                  placeholder="Subject"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" v-if="CcMailFlag">
                <label>
                  <h4 class="text-primary">
                    CC Mails
                    <small>(optional)</small>
                  </h4>
                </label>
                <v-text-field
                  v-model="CCMails"
                  :rules="CCMailsRules"
                  placeholder="(ex: abc@mail.com,xyz@mail.com,...,etc)"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" md="12">
                <h4 class="text-primary">
                  <span class="text-danger">*</span> Email Content
                </h4>
                <p class="notes-text1">
                  Kindly note, the following variables can be used in email:
                  <span>
                    <ul class="notes-text1">
                      <li>Name: <b> #name# </b></li>
                      <li>Designation: <b> #designation# </b></li>
                      <li>LO name: <b> #loname# </b></li>
                      <li>Zone name: <b> #zonename# </b></li>
                    </ul>
                  </span>
                </p>
                <ckeditor v-model="Content" :config="editorConfig"></ckeditor>
                <!-- <tiptap-vuetify
                  v-model="Content"
                  :extensions="extensions"
                  :toolbar-attributes="{ color: 'blue' }"
                /> -->
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" md="4" lg="4">
                <h4 class="text-primary">
                  Attachment <small>(optional)</small>
                </h4>
                <h6>Maximum file upload size is 5MB</h6>
                <!-- accept="application/pdf" -->
                <v-file-input
                  :clearable="false"
                  truncate-length="25"
                  placeholder="Upload PDF File"
                  v-model="UploadedFile"
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:append-outer>
                    <v-icon color="red" @click="UploadedFile = {}">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4">
                <h4 class="text-primary">
                  Attachment <small>(optional)</small>
                </h4>
                <h6>Maximum file upload size is 5MB</h6>
                <!-- accept="application/pdf" -->
                <v-file-input
                  :clearable="false"
                  truncate-length="25"
                  placeholder="Upload PDF File"
                  v-model="UploadedFile1"
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:append-outer>
                    <v-icon color="red" @click="UploadedFile1 = {}">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="submitForm"
                  :loading="SubmitFlag"
                  elevation="30"
                  shaped
                  tile
                  small
                  color="#1db954"
                  class="font-size-h6 px-10 mr-3 white--text"
                  >Send</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  mixins: [common],
  components: { TiptapVuetify },
  props: {},
  data() {
    return {
      valid1: true,
      ProgressLoadingFlag: false,
      LoadingFlag: false,
      SubmitFlag: false,

      Subject: "",
      SubjectRules: [(v) => !!v || "Subject is required"],

      UploadedFile: {},
      UploadedFile1: {},

      Content: "",
      ContentRules: [(v) => !!v || "Content is required"],
      editorConfig: {
        toolbar: [
          { name: "styles", items: ["Format", "Styles", "Font", "FontSize"] },
          {
            name: "basicstyles",
            groups: ["basicstyles", "cleanup"],
            items: [
              "Bold",
              "Italic",
              "Underline",
              "Strike",
              "Subscript",
              "Superscript",
              "-",
              "RemoveFormat",
            ],
          },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi"],
            items: [
              "NumberedList",
              "BulletedList",
              "-",
              "Outdent",
              "Indent",
              "-",
              "Blockquote",
              "CreateDiv",
              "-",
              "JustifyLeft",
              "JustifyCenter",
              "JustifyRight",
              "JustifyBlock",
              "-",
              "BidiLtr",
              "BidiRtl",
              "Language",
            ],
          },
          { name: "links", items: ["Link", "Unlink", "Anchor"] },
          {
            name: "insert",
            items: [
              "Image",
              "Flash",
              "Table",
              "HorizontalRule",
              "Smiley",
              "SpecialChar",
              "PageBreak",
              "Iframe",
            ],
          },
          {
            name: "clipboard",
            groups: ["clipboard", "undo"],
            items: [
              "Cut",
              "Copy",
              "Paste",
              "PasteText",
              "PasteFromWord",
              "-",
              "Undo",
              "Redo",
            ],
          },
          {
            name: "editing",
            groups: ["find", "selection", "spellchecker"],
            items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"],
          },
          {
            name: "forms",
            items: [
              "Form",
              "Checkbox",
              "Radio",
              "TextField",
              "Textarea",
              "Select",
              "Button",
              "ImageButton",
              "HiddenField",
            ],
          },
          { name: "colors", items: ["TextColor", "BGColor"] },
          { name: "tools", items: ["Maximize", "ShowBlocks"] },
          {
            name: "document",
            groups: ["mode", "document", "doctools"],
            items: [
              "Source",
              "-",
              "Save",
              "NewPage",
              "Preview",
              "Print",
              "-",
              "Templates",
            ],
          },
        ],
      },

      CCMails: "",
      CCMailsRules: [],
      CcMailFlag: false,

      ReplyToEmailId: "",
      ReplyToEmailIdRules: [
        (v) => !!v || "Reply to e-mail id is required",
        (v) => /.+@.+\..+/.test(v) || "Reply to e-mail id must be valid",
      ],

      MemberType: "",
      MemberTypeRules: [(v) => !!v || "Member Type is required"],
      MemberTypeOptions: [
        { value: "", text: "Select Member Type" },
        { value: 1, text: "JCI India Members" },
        { value: 2, text: "Board Members" },
        { value: 3, text: "JAC Members" },
      ],
      MemberTypeOptionsLoading: false,

      JciYearCodeFlag: false,
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],

      ZoneCode: "",
      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCode: [],
      LomCodeRules: [],
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      YearHalf: "",
      YearHalfRules: [],
      YearHalfOptions: [
        { value: "", text: "Select Half" },
        { value: "First", text: "First Half" },
        { value: "Second", text: "Second Half" },
      ],

      OrganizationType: "",
      OrganizationTypeRules: [(v) => !!v || "Governing Board Type is required"],
      OrganizationTypeOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "National" },
        { value: 2, text: "Zone" },
        { value: 3, text: "LO" },
      ],
      OrganizationTypeOptionsLoading: false,

      OrganizationType1: "",
      OrganizationTypeRules1: [(v) => !!v || "Organization Type is required"],
      OrganizationTypeOptions1: [],
      OrganizationTypeOptionsLoading1: false,

      DesignationAreaCode: "",
      DesignationAreaCodeOptions: [],
      DesignationAreaCodeRules: [],
      DesignationAreaCodeOptionsLoading: false,

      DesignationZoneCode: "",
      DesignationZoneCodeRules: [],
      DesignationZoneCodeOptions: [],
      DesignationZoneCodeOptionsLoading: false,

      DesignationLomCode: "",
      DesignationLomCodeRules: [],
      DesignationLomCodeOptions: [],
      DesignationLomCodeOptionsLoading: false,

      DesignationCode: [],
      DesignationCodeRules: [],
      DesignationCodeOptions: [],
      DesignationCodeOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      EmailCredits: 0,

      extensions: [
        History,
        Blockquote,
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        var EmailCredits = parseInt(tr.EmailCredits);
        console.log({ EmailCredits });
        this.EmailCredits = EmailCredits;

        this.getJciYearCodeOptions();
        this.getOrganizationTypeOptions();
        this.getZoneCodeOptions();
        this.getDesignationAreaCodeOptions();
      }
    },
    MemberType: function () {
      console.log("watch MemberType");
      var MemberType = this.MemberType;
      console.log("MemberType=" + MemberType);

      this.ZoneCode = "";
      this.LomCode = [];
      this.YearHalf = "";
      this.OrganizationType = "";
      this.DesignationCode = [];
      this.DesignationZoneCode = "";
      this.DesignationLomCode = "";
    },
    JciYearCode: function () {
      console.log("Watch JciYearCode called");
      this.JciYearCodeFlag = false;
    },
    OrganizationType1: function () {
      console.log("Watch JciYearCode called");
      this.OrganizationTypeOptionsLoading1 = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
    DesignationAreaCodeOptions: function () {
      console.log("watch DesignationAreaCodeOptions");
      this.DesignationAreaCodeOptionsLoading = false;
    },
    DesignationZoneCodeOptions: function () {
      console.log("watch DesignationZoneCodeOptions");
      this.DesignationZoneCodeOptionsLoading = false;
    },
    DesignationLomCodeOptions: function () {
      console.log("watch DesignationLomCodeOptions");
      this.DesignationLomCodeOptionsLoading = false;
    },

    DesignationCodeOptions: function () {
      console.log("watch DesignationCodeOptions");
      this.DesignationCodeOptionsLoading = false;
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "communication",
        Action: "report_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 5,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      this.ZoneCodeOptionsLoading = true;
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      this.LomCodeOptionsLoading = true;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getDesignationAreaCodeOptions() {
      console.log("getDesignationAreaCodeOptions called");
      this.DesignationAreaCodeOptionsLoading = true;
      var selectbox1_source = "DesignationAreaCode";
      var selectbox1_destination = "DesignationAreaCodeOptions";
      var selectbox1_url = "api/year-wise-lom/area-options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        YearCode: this.JciYearCode,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDesignationZoneCodeOptions() {
      console.log("getDesignationZoneCodeOptions called");

      var yearCode = this.JciYearCode;
      var areaCode = this.DesignationAreaCode;
      console.log("yearCode=" + yearCode + ", areaCode=" + areaCode);
      if (yearCode != "" && areaCode != "") {
        this.DesignationZoneCode = "";
        this.DesignationZoneCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "DesignationZoneCode";
        var selectbox1_destination = "DesignationZoneCodeOptions";
        var selectbox1_url = "api/year-wise-zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 6,
          AreaCode: areaCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getDesignationLomCodeOptions() {
      console.log("getDesignationLomCodeOptions called");
      this.DesignationLomCode = "";

      var YearCode = this.JciYearCode;
      var ZoneCode = this.DesignationZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.DesignationLomCodeOptionsLoading = true;
        var selectbox1_source = "DesignationLomCode";
        var selectbox1_destination = "DesignationLomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getDesignationCodeOptions() {
      console.log("getDesignationCodeOptions called");
      this.DesignationAreaCode = "";
      this.DesignationZoneCode = "";
      this.DesignationLomCode = "";
      this.DesignationCodeOptionsLoading = true;
      var selectbox1_source = "DesignationCode";
      var selectbox1_destination = "DesignationCodeOptions";
      var selectbox1_url = "api/designation/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        OrganizationTypeId: this.OrganizationType,
        AllOption: 0,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    resetForm() {
      this.$refs.form1.reset();
      this.MemberType = "";
      this.ZoneCode = "";
      this.OrganizationType = "";
      this.Subject = "";
      this.Content = "";
      this.CCMails = "";
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        const formData = new FormData();

        formData.append("UserInterface", 1);
        formData.append("Year", this.JciYearCode);
        formData.append("MemberType", this.MemberType);
        formData.append("Zone", this.ZoneCode);

        for (var i = 0; i < this.LomCode.length; i++) {
          formData.append("Lom[]", this.LomCode[i]);
        }
        // formData.append("Lom", this.LomCode);
        formData.append("YearHalf", this.YearHalf);
        formData.append("OrganizationType", this.OrganizationType);
        formData.append("OrganizationType1", this.OrganizationType1);

        for (var j = 0; j < this.DesignationCode.length; j++) {
          formData.append("Designation[]", this.DesignationCode[i]);
        }
        // formData.append("Designation", this.DesignationCode);
        formData.append("ReplyTo", this.ReplyToEmailId);
        formData.append("Subject", this.Subject);
        formData.append("Content", this.Content);
        formData.append("CCMails", this.CCMails);
        formData.append("DesignationArea", this.DesignationAreaCode);
        formData.append("DesignationZone", this.DesignationZoneCode);
        formData.append("DesignationLom", this.DesignationLomCode);
        if (this.UploadedFile !== "") {
          formData.append("UploadFile", this.UploadedFile);
        }
        if (this.UploadedFile1 !== "") {
          formData.append("UploadFile1", this.UploadedFile1);
        }
        this.sendEmail(formData);
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
    sendEmail(upload) {
      console.log("sendEmail is called");

      console.log({ upload });

      this.SubmitFlag = true;
      this.ProgressLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/communication/send-members-email";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SubmitFlag = false;
          thisIns.ProgressLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          records = response.data.records;
          console.log({ records });

          var EmailCredits = records.EmailCredits;
          console.log({ EmailCredits });

          thisIns.EmailCredits = EmailCredits;

          if (flag == 1) {
            thisIns.sweetAlert("success", output, false);
            // thisIns.refreshPageData();
            thisIns.resetForm();
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
          thisIns.ProgressLoadingFlag = false;
        });
    },
    getOrganizationTypeOptions() {
      console.log("getOrganizationTypeOptions called");
      this.OrganizationTypeOptionsLoading1 = true;
      this.OrganizationType1 = "";
      var selectbox1_source = "OrganizationType1";
      var selectbox1_destination = "OrganizationTypeOptions1";
      var selectbox1_url = "api/organization-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#send-members-email {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-text1 {
    padding: 5px;
    margin-left: 2rem;
    font-size: 14px;
    font-family: sans-serif;
  }
}
</style>